import localforage from "localforage";
const config = {
   BASE_URL: `${process.env.REACT_APP_API_URL}`,
  // TEST_SERVER
  MAKLARSERVICE_URL: "https://staging-maklarservice.cortexcraft.com/",
  isProd:false,
  //PROD_SERVER
//  MAKLARSERVICE_URL: "https://maklarservice.com/",
  //isProd : true,
  SMOOTH_URL: "https://smoot.se/syna_lending_api/",
  email_pattern: new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  ),
  ORG_NUM: "5569166969",
  LOCAL_FORAGE: localforage.createInstance({
    name: "signator",
  }),
  ROLES: {
    SU: "Styrelsesuppleant",
    LE: "Styrelseledamot",
    VD: "Verkställande direktör",
    OF: "Ordförande",
    ARB: "Arbetstagarrepresentant",
    EVD: "Extern VD",
    EFT: "Extern firmatecknare",
    REV: "Revisor",
    REVH: "Huvudansvarig revisor",
  },
  validMembers: ["LE", "REV", "REVH", "REVL", "REVT", "REVEX", "REVIN"],
   accountants: ["REV", "REVH", "REVL", "REVT", "REVEX", "REVIN"],
  systemMembers: ["LE", "REV", "REVH", "REVL", "REVT","VD"],
  manualMembers: ["REVEX", "REVIN"],
  annualPrice: 399,
  otherDocument_annualPrice: 56,
  postalCharges: 55,
  VAT_Paper_invoice: "113,5",
  VAT_Email_invoice: "99,75",
  TOTAL_Paper_invoice: "567,5",
  TOTAL_Email_invoice: "498,75",
};
export default config;
